<template>
	<div
		id="main-menu"
		class="absolute margin-center"
		v-if="menu && menu.length > 0"
	>
		<div class="rounded-l p-xs relative box-shadow">
			<div class="">
				<div id="content">
					<div id="menu-items">
						<div
							v-for="(item, index) in menu"
							v-bind:key="'main_menu_' + index"
							class="p-l-xs p-r-xs"
						>
							<router-link :to="item.to" v-bind:class="item.class">
								<div
									class="main-menu-icon flex-center-content margin-center grey1-color white-bg full-rounded box-shadow1"
								>
									<Icon :icon="item.icon" size="35" />
								</div>
								<div class="text-center font-s m-t-sm label grey1-color">
									{{ $t(item.label) }}
								</div>
							</router-link>
						</div>
					</div>
					<div v-if="asset_id" id="main-menu-close" class="flex-center-content">
						<router-link :to="{ name: $route.name }">
							<div class="grey1-color text-center">
								<Icon icon="close" />
							</div>
						</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import UserPermissionsUtilClass from "@src/user/application/utils/user-permissions-util";
const UserPermissionsUtil = new UserPermissionsUtilClass();

export default {
	name: "MainMenu",
	computed: {
		menu() {
			let options = [];
			if (this.asset_id) {
				if (UserPermissionsUtil.can_user("edit_asset_info")) {
					options.push({
						label: "index.asset_menu.overview",
						icon: "gear",
						class: "display-only-large-devices",
						to: {
							name: "asset-info",
							params: {
								asset_id: this.asset_id
							}
						}
					});
				}

				options.push({
					label: "index.asset_menu.main_panel",
					icon: "dashboard",
					to: {
						name: "asset-main-panel",
						params: {
							asset_id: this.asset_id
						}
					}
				});
			}

			return options;
		},
		asset_id() {
			return this.$route.query.asset_id || null;
		}
	}
};
</script>

<style scoped>
#main-menu {
	bottom: 25px;
	z-index: 1;
	left: 50%;
	transform: translate(-50%);
}
#main-menu > div {
	background-color: rgba(255, 255, 255, 0.7);
}
#content,
#menu-items {
	display: flex;
	justify-content: flex-start;
	flex-wrap: nowrap;
	flex-direction: row;
}
.main-menu-icon {
	width: 60px;
	height: 60px;
}
.label {
	line-height: 15px;
}
#main-menu-close {
	font-size: 25px;
}
</style>
