<template>
	<div>
		<div v-if="loading">
			<Loader size="30" />
		</div>
		<div v-else class="display-flex">
			<div
				id="measurments-resolution-selector"
				class="display-flex grey3-bg font-bold p-xxs rounded-s grey1-color"
			>
				<div
					v-for="(data, index) in resolution_options"
					v-bind:class="{
						active: selected_resolution == data.key,
						'm-l-sm': index > 0,
						'm-r-sm': index < 3
					}"
					v-on:click="change_resolution(data.key)"
					class="rounded-s cursor-pointer flex-center-content"
				>
					<div>
						{{ data.label }}
					</div>
				</div>
			</div>

			<div
				class="m-l grey3-bg"
				v-if="selected_dates_range && selected_resolution != 'yearly'"
			>
				<Datepicker
					v-if="datepicker_min_view == 'day'"
					v-model="selected_dates_range"
					:is_range="true"
					:max_range_days="6"
					:min_view="datepicker_min_view"
					:allow_from="date_allow_from"
					:allow_to="date_allow_to"
				/>
				<Datepicker
					v-else
					v-model="selected_dates_range"
					:min_view="datepicker_min_view"
					:allow_from="date_allow_from"
					:allow_to="date_allow_to"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Datepicker from "@form_components/Datepicker.vue";

import moment from "moment";
import AssetEnergyAvailableDateFinderClass from "@src/asset-energy-data/application/asset-energy-available-date-finder.js";
import AssetEnergyForecastAvailableDateRangeFinderClass from "@src/forecast/application/asset-energy-forecast-available-date-range-finder.js";

const AssetEnergyAvailableDateFinder =
	new AssetEnergyAvailableDateFinderClass();
const AssetEnergyForecastAvailableDateRangeFinder =
	new AssetEnergyForecastAvailableDateRangeFinderClass();

export default {
	name: "MeasurementsFilter",
	components: {
		Loader,
		Datepicker
	},
	data() {
		return {
			loading: false,
			error: null,
			date_allow_from: null,
			date_allow_to: null,
			asset_asset_energy_measurements_available_date_range: {
				day_from: null,
				day_to: null
			},
			asset_asset_energy_forecast_available_date_range: null,
			selected_resolution: "optimal",
			selected_dates_range: null,
			no_data: null,
			asset_has_energy_forecast_data: null
		};
	},
	async mounted() {
		this.loading = true;

		try {
			const asset_id = this.$route.params.asset_id;
			var selected_dates_range;

			this.asset_asset_energy_measurements_available_date_range.day_to =
				await AssetEnergyAvailableDateFinder.get_asset_energy_data_last_date(
					asset_id
				);

			this.asset_asset_energy_measurements_available_date_range.day_from =
				await AssetEnergyAvailableDateFinder.get_asset_energy_data_first_date(
					asset_id
				);

			if (this.asset_asset_energy_measurements_available_date_range.day_to) {
				selected_dates_range = [
					this.asset_asset_energy_measurements_available_date_range.day_to,
					this.asset_asset_energy_measurements_available_date_range.day_to
				];
			}

			this.asset_asset_energy_forecast_available_date_range =
				await AssetEnergyForecastAvailableDateRangeFinder.get_asset_energy_production_forecast_available_date_range(
					asset_id
				);

			if (
				(!this.asset_asset_energy_measurements_available_date_range ||
					!this.asset_asset_energy_measurements_available_date_range.day_to) &&
				(!this.asset_asset_energy_forecast_available_date_range ||
					!this.asset_asset_energy_forecast_available_date_range.day_to)
			) {
				this.no_data = true;
				this.emit_filter();
				return;
			}

			if (
				this.asset_asset_energy_forecast_available_date_range &&
				this.asset_asset_energy_forecast_available_date_range.day_from &&
				this.asset_asset_energy_forecast_available_date_range.day_to
			) {
				this.asset_has_energy_forecast_data = true;

				if (
					!this.date_allow_to ||
					moment(
						this.asset_asset_energy_forecast_available_date_range.day_to
					).isAfter(this.date_allow_to)
				) {
					const day_from =
						!this.date_allow_to || moment().isBefore(this.date_allow_to)
							? new Date()
							: this.date_allow_to;

					const day_to =
						this.date_allow_to &&
						moment(this.date_allow_to).diff(day_from, "day") <= 5
							? this.date_allow_to
							: moment(day_from).add(5, "days").toDate();

					selected_dates_range = [day_from, day_to];
				}
			}

			this.selected_dates_range = selected_dates_range;
		} catch (error) {
		} finally {
			this.loading = false;
		}
	},
	computed: {
		resolution_options() {
			return [
				{
					key: "optimal",
					label: this.$t("asset_energy_panel.day")
				},
				{
					key: "daily",
					label: this.$t("asset_energy_panel.month")
				},
				{
					key: "monthly",
					label: this.$t("asset_energy_panel.year")
				},
				{
					key: "yearly",
					label: this.$t("asset_energy_panel.total")
				}
			];
		},
		datepicker_min_view() {
			switch (this.selected_resolution) {
				case "optimal":
					return "day";
					break;
				case "daily":
					return "month";
					break;
				case "monthly":
					return "year";
					break;
			}
		},
		date_allow_from() {
			return this.get_date_allow_from();
		},
		date_allow_to() {
			var result = null;

			if (this.asset_asset_energy_measurements_available_date_range.day_to) {
				result =
					this.asset_asset_energy_measurements_available_date_range.day_to;
			}

			if (
				this.asset_asset_energy_forecast_available_date_range &&
				this.asset_asset_energy_forecast_available_date_range.day_to &&
				(!result ||
					moment(
						this.asset_asset_energy_forecast_available_date_range.day_to
					).isAfter(result))
			) {
				result = this.asset_asset_energy_forecast_available_date_range.day_to;
			}

			return result ? new Date(result) : null;
		}
	},
	methods: {
		get_date_allow_from() {
			var result = null;

			if (this.asset_asset_energy_measurements_available_date_range.day_from) {
				result =
					this.asset_asset_energy_measurements_available_date_range.day_from;
			}

			if (
				this.asset_asset_energy_forecast_available_date_range &&
				this.asset_asset_energy_forecast_available_date_range.day_from &&
				(!result ||
					moment(
						this.asset_asset_energy_forecast_available_date_range.day_from
					).isBefore(result))
			) {
				result = this.asset_asset_energy_forecast_available_date_range.day_from;
			}

			if (
				this.datepicker_min_view == "day" &&
				moment().diff(result, "days") >= 365
			) {
				result = moment().subtract(1, "years").toDate();
			}

			return result ? new Date(result) : null;
		},
		change_resolution(new_resolution) {
			if (this.selected_resolution == new_resolution) return;

			this.selected_resolution = new_resolution;

			if (new_resolution == "optimal") {
				const date_allow_from = this.get_date_allow_from();

				if (
					date_allow_from &&
					moment(this.selected_dates_range).isBefore(date_allow_from)
				) {
					this.selected_dates_range = [date_allow_from, date_allow_from];
				} else {
					this.selected_dates_range = [
						this.selected_dates_range,
						this.selected_dates_range
					];
				}
			} else if (Array.isArray(this.selected_dates_range)) {
				this.selected_dates_range = this.selected_dates_range[1];
			}
		},
		emit_filter() {
			this.$emit(
				"filter_emited",
				this.no_data
					? { no_data: true }
					: {
							resolution: this.selected_resolution,
							date: this.selected_dates_range,
							asset_has_energy_forecast_data:
								this.asset_has_energy_forecast_data,
							asset_asset_energy_measurements_available_date_range:
								this.asset_asset_energy_measurements_available_date_range,
							asset_asset_energy_forecast_available_date_range:
								this.asset_asset_energy_forecast_available_date_range
					  }
			);
		}
	},
	watch: {
		selected_dates_range(new_value) {
			this.emit_filter();
		},
		selected_resolution(new_value) {
			if (new_value == "yearly") this.emit_filter();
		}
	}
};
</script>

<style scoped>
#measurments-resolution-selector {
	border: 1px solid #ced4da;
}
#measurments-resolution-selector > div {
	padding: 0 8px;
}
#measurments-resolution-selector > div.active {
	background-color: var(--grey1);
	color: white;
}
</style>
